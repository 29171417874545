canopyPublic.controller("ConfirmController", ["$scope", "$stateParams", "$state", "LumaPublicAPI", "canopyModals", function ($scope, $stateParams, $state, LumaPublicAPI, canopyModals) {
	var init = function () {
		$scope.confirmation = {
			uuid: ""
		};
		
		if ($stateParams.uuid) {
			$scope.confirmation.uuid = $stateParams.uuid;
		}
	};
	
	$scope.submitConfirmation = function () {
		var onSuccess = function (response) {
			var messages = {
				"NotFound"        : "The confirmation token you supplied is not recognised, please check and try again.",
				"NotAllowed"      : "Self registration is not allowed for this user, please check and try again.",
				"AlreadyApproved" : "This registration has already been approved and confirmed, please go ahead and login.",
				"AlreadyRejected" : "Unfortunately this registration has already been rejected by an approver.",
				"Confirmed"       : "Your registration has been successfully confirmed. You can now login using your new account."
			};
			
			var modal = null;
			var code  = response.data.code;
			
			if (code) {
				var message = messages[code];
				
				if (message) {
					modal = canopyModals.definitions.message({
						header: "Confirm registration",
						body: message
					});
				}
			}
			
			if (modal) {
				canopyModals.instantiate(modal).result.then(function () {
					if (code == "Confirmed") {
						$state.go("public.login");
					}
				});
			}
		};
		
		var onError = function (response) {
			var messages = {
				"NotFound"        : "The confirmation token you supplied is not recognised, please check and try again.",
				"NotAllowed"      : "Self registration is not allowed for this user, please check and try again.",
				"AlreadyApproved" : "This registration has already been approved and confirmed, please go ahead and login.",
				"AlreadyRejected" : "Unfortunately this registration has already been rejected by an approver.",
				"Confirmed"       : "Your registration has been successfully confirmed, please go ahead and login."
			};
			
			var modal = null;
			
			if (response.data.reasons) {
				var uuidError = response.data.reasons.find(function (element) {
					return element.key == "uuid";
				});
				
				if (uuidError) {
					modal = canopyModals.definitions.message({
						header: "Confirm registration",
						body: "Then token you entered is not valid."
					});
				}
			}
			
			modal = modal || canopyModals.definitions.serverError(response.data);
			
			canopyModals.instantiate(modal);
		};
		
		LumaPublicAPI.confirmRegistration($scope.confirmation).then(onSuccess, onError);
	};
		
	// Begin initialisation
	init();
}]);
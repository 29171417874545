canopyPublic.controller("RegisterController", ["$scope", "$http", "$location", "$state", "LumaPublicAPI", "canopyModals", function ($scope, $http, $location, $state, LumaPublicAPI, canopyModals) {
    var init = function () {
		var host = $location.host();
		
		$scope.ancillary = {
			formURL: Luma.paths.context + "/servlet/hosted/forms/register.html",
			domains: {
				available: [],
				selected: null
			},
			groups: {
				available: []
			}
		};
		
		initRegistration();
		initDomains();
	};
	
	var initRegistration = function () {
		$scope.registration = {
			email: "",
			password: "",
			forename: "",
			surname: "",
			domainID: null,
			groupIDs: []
		};

		retrieveDeletedUserInfo();

	};

	var retrieveDeletedUserInfo = function () {
		var obj = JSON.parse(localStorage.getItem("deletedUserInfo"));

		if (obj) {

			$scope.showWarningModal = true;

			$scope.registration.forename = obj.forename;
			$scope.registration.email = obj.email;
			$scope.registration.surname = obj.surname;
		}
	}

	var deleteUserStoredInfo = function () {
		var obj = JSON.parse(localStorage.getItem("deletedUserInfo"));

		if (obj) {
  		return localStorage.removeItem("deletedUserInfo");
		}
	}
	
	var initDomains = function () {
		var onSuccess = function (response) {	
			$scope.ancillary.domains.available = [];
			
			var parseDomain = function (domain, depth) {
				for (var i = 0; i != depth; ++i) {
					domain.title = "- " + domain.title;
				}
				
				$scope.ancillary.domains.available.push(domain);
				
				if (domain.subdomains) {
					domain.subdomains.forEach( function (element) {
						parseDomain(element, depth + 1);
					});
				
					delete domain.subdomains;
				}
			};
			
			response.data.forEach(function (domain) {
				parseDomain(domain, 0);
			});
		};
		
		var onError = function (response) {
			
		};
		
		$http({
			method: "GET",
			url: Luma.paths.context + "/servlet/interface/com.intrepia.luma.ListRegistrationDomainTree"
		}).then(onSuccess).catch(onError);
	};
	
	$scope.onSubmit = function (form) {
    	$scope.$broadcast("submit");
			
    	if (form.$invalid) {
    		var modal = canopyModals.definitions.message({
    			header: "Could not submit registration",
    			body: "You haven't entered any data into one or more required form fields. Please check the form, enter the required information, and resubmit."
    		});
    		
    		canopyModals.instantiate(modal).result.then(function () {
		    	$scope.isSubmitting = false;
    		});
    		
		    return;
    	}
    	
    	$scope.isSubmitting = true;
    	
		if ($scope.ancillary.domains.selected) {
			$scope.registration.domainID = $scope.ancillary.domains.selected.id;
		}
		
		$scope.registration.groupIDs       = null;
		$scope.registration.primaryGroupID = null;
		
		var selectedGroups = [];
		
		for (var type in $scope.ancillary.groups.available) {
			var selected = $scope.ancillary.groups.available[type].filter(function (group) { 
				return group.isSelected;
			});
			
			selectedGroups = selectedGroups.concat(selected);
		}
		
		var primaryGroup = selectedGroups.find(function (group) {
			return group.isPrimary;
		});
		
		$scope.registration.groupIDs = selectedGroups.map(function (group) {
			return group.id;
		}).toString();
		
		if (primaryGroup) {
			$scope.registration.primaryGroupID = primaryGroup.id;
		}
		
		if (selectedGroups.length && !primaryGroup) {
			var modal = canopyModals.definitions.message({
				header: "No primary group",
				body: "Please select a primary group"
			});
		
			canopyModals.instantiate(modal);
			
    		$scope.isSubmitting = false;
    	
			return;
		}
		
		var onSuccess = function (response) {		
			var modal = canopyModals.definitions.message({
				header: "Success",
				body: "Your registration was successful. A confirmation email will be sent to the email address you used to register. For reference, your unique registration ID is: " + response.data + "."
			});
			
			$scope.isSubmitting = false;
			deleteUserStoredInfo();

			canopyModals.instantiate(modal).result.then(function () {
		    	$state.go("public.login");
			});
		};
		
		var onError = function (response) {
			var modal = canopyModals.definitions.serverError(response.data);
		
			canopyModals.instantiate(modal);
			
	    	$scope.isSubmitting = false;
		};
		
		LumaPublicAPI.register($scope.registration).then(onSuccess).catch(onError);
	};
	
	$scope.$watch("ancillary.domains.selected", function (domain) {
		var groupFilter = function (type) {
			return function (element) {
				return element.type == type;
			};
		};
		
		var onSuccess = function (response) {
			$scope.ancillary.groups.available = {
				permission: response.data.filter( groupFilter("Permission") ),
				unit:       response.data.filter( groupFilter("Unit") ),
				region:     response.data.filter( groupFilter("Region") ),
				branch:     response.data.filter( groupFilter("Branch") )
			};
		};

		if (domain && domain.id) {
			LumaPublicAPI.listRegistrationGroups(domain.id).then(onSuccess);
		}
    
    
    });
    
    $scope.canopyRegistrationGroupManagerValid = true;

    $scope.$on("canopyRegistrationGroupManagerValidityEvent", function(event, args) {
        $scope.canopyRegistrationGroupManagerValid = args.valid;
    });
	
	init();
}]);

"use strict";

console.log("marqueePublic/_main.js");

var canopyPublic = angular.module("canopyPublic", ["ui.router", "ui.bootstrap", "ngAnimate", "ngSanitize", "canopyUtils"]);

canopyPublic.controller("ApplicationController", ["$scope", "$timeout", "$location", "$state", "$stateParams", "$http", function ($scope, $timeout, $location, $state, $stateParams, $http) {
	$scope.application = {
		paths: Luma.paths,
		host: $location.host()
	};
}]);

canopyPublic.run(["$state", "$stateParams", function ($state, $stateParams) {
	// This solves page refresh and getting back to state
}]);

canopyPublic.config(["$locationProvider", function ($locationProvider) {
	$locationProvider.html5Mode(false);
}]);

canopyPublic.config(["$stateProvider", "$urlRouterProvider", function ($stateProvider, $urlRouterProvider) {
	$stateProvider.state("public", {
		url: "",
		"abstract": true,
		templateUrl: Luma.paths.context + "/system/mantle/marquee/public/views/canopy.html"
	});
	
	$stateProvider.state("public.register", {
		url: "/register",
		controller: "RegisterController",
		templateUrl: Luma.paths.context + "/system/mantle/marquee/public/views/states/register.html"
	});
	
	$stateProvider.state("public.reset-request", {
		url: "/reset-request",
		controller: "ResetRequestController",
		templateUrl: Luma.paths.context + "/system/mantle/marquee/public/views/states/reset-request.html"
	});
	
	$stateProvider.state("public.reset", {
		url: "/reset?token",
		controller: "ResetController",
		templateUrl: Luma.paths.context + "/system/mantle/marquee/public/views/states/reset.html"
	});
	
	$stateProvider.state("public.confirm", {
		url: "/confirm?uuid",
		controller: "ConfirmController",
		templateUrl: Luma.paths.context + "/system/mantle/marquee/public/views/states/confirm.html"
	});
	
	$stateProvider.state("public.terms", {
		url: "/terms",
		templateUrl: Luma.paths.context + "/system/mantle/marquee/public/views/states/terms.html"
	});
	
	$stateProvider.state("public.depot", {
		url: "/depot?guestUUID",
		controller: "DepotController",
		templateUrl: Luma.paths.context + "/system/mantle/marquee/public/views/states/depot.html"
	});
	
	$stateProvider.state("public.login", {
		url: "*path",
		controller: "LoginController",
		templateUrl: Luma.paths.context + "/system/mantle/marquee/public/views/states/login.html"
	});
}]);

canopyPublic.config(["$httpProvider", function ($httpProvider) {  
    $httpProvider.interceptors.push("errorInterceptor");
}]);

/* ==================== */
/* 	 INTERCEPTORS       */
/* ==================== */

canopyPublic.factory("errorInterceptor", ["$window", "$q", "$injector", function ($window, $q, $injector) {  
	 return {
        responseError: function (responseError) {
			var canopyModals = $injector.get("canopyModals");
			
			var modal = canopyModals.definitions.message({
				header: responseError.statusText,
				body: responseError.data
			});
			        	
            return $q.reject(responseError);
        }
    };
}]);

canopyPublic.run(["$transitions", function ($transitions) {
	// Remove the requirement to pass a "path" argument when transitioning to the wildcard login state.
	$transitions.onBefore({}, function (transition) {
		var targetState = transition.targetState();
		var name       = targetState.name();
		var parameters = targetState.params();
		
		if (name == "public.login" && parameters.path == undefined) {
			return transition.router.stateService.target("public.login", {
				path: ""
			});
		}
	});
}]);
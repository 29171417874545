canopyPublic.controller("ResetController", ["$scope", "$stateParams", "$state", "LumaPublicAPI", "canopyModals", function ($scope, $stateParams, $state, LumaPublicAPI, canopyModals) {
	var init = function () {
		$scope.reset = {
			token: "",
			password: ""
		};
		
		if ($stateParams.token) {
			$scope.reset.token = $stateParams.token;
		}
	};
	
	$scope.submitReset = function () {
		var onSuccess = function () {
			var modal = canopyModals.definitions.message({
				header: "Password reset",
				body: "Your password has been reset. You can now login using your new password.",
				confirm: "Return to login"
			});
			
			canopyModals.instantiate(modal).result.then( function () {
				$state.go("public.login");
			});
		};
		
		var onError = function (response) {
			var modal = canopyModals.definitions.serverError(response.data);
			
			canopyModals.instantiate(modal);
		};
		
		LumaPublicAPI.resetUserPassword($scope.reset).then(onSuccess).catch(onError);
	};
		
	// Begin initialisation
	init();
}]);
canopyPublic.controller("DepotController", ["$scope", "$stateParams", "LumaPublicAPI", "$timeout", function ($scope, $stateParams, LumaPublicAPI, $timeout) {
	var init = function () {
		$scope.share = null;
		$scope.error = null;
		$scope.paths = Luma.paths;
		
		if ($stateParams && $stateParams.guestUUID) {
			$scope.guestUUID = $stateParams.guestUUID;
			loadShare();
		}
		else {
			$scope.error = "No share ID specified"; 
		}
	};
    
    var loadShare = function () {
    	var onSuccess = function (response) {
    		if (response.data) {
    			if (response.data.status === "Available") {
					$scope.share = response.data;
					
					loadShareAssets();
    			}
    			else {
					switch (response.data.status) {
						case "Unfound":
							$scope.error = "This share was not found";
							break;
						
						default:
							$scope.error = "An unexpected error occurred";
							break;
					}
				}
    		}
    	};
    	
    	var onError = function () {
    		$scope.error = "An unexpected error occurred";
    	};
    	
    	LumaPublicAPI.getShareStatus($stateParams.guestUUID).then(onSuccess).catch(onError);
    };
        
    var loadShareAssets = function () {
    	var onSuccess = function (response) {
			$scope.share.assets = response.data;
    	};
    	
    	var onError = function () {
    	};
    	
    	LumaPublicAPI.listPublicShareAssets($stateParams.guestUUID).then(onSuccess).catch(onError);
    };
    
    init();  
}]);
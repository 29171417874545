canopyPublic.controller("LoginController", ["$scope", "$timeout", "$window", "$location", "LumaPublicAPI", function ($scope, $timeout, $window, $location, LumaPublicAPI) {
	$scope.controller = {
		loginState: {
			login: "",
			password: "",
			agreedTerms: false,
			canLogin: false,
			isSubmitting: false,
			error: null
		},
		sso: {
			providers: [],
			state: null
		}
	};
	
	var initSSO = function () {
		var onSuccess = function (response) {
			$scope.controller.sso.providers = response.data;
			
			$scope.controller.sso.providers.forEach(function (provider) {
				provider.reference = provider.name.replace(/ /g, "_").toLowerCase();
			});
		};
		
		var path = $location.path();
		
		if (path != "/" && path != "") {
			$scope.controller.sso.state = btoa(path);
		}
		
		LumaPublicAPI.getSSOProviders().then(onSuccess);
	};
	
	$scope.onSSOProviderSelected = function (provider) {
		LumaPublicAPI.initiateSSO(provider, $scope.controller.sso.state || null);
	};
	
	initSSO();
	
	$scope.$watch("controller.loginState", function (loginState) {
		loginState.canLogin = loginState.login &&
							  loginState.password &&
							  loginState.agreedTerms;
	}, true);
			
	$scope.submitLogin = function () {
		$scope.controller.loginState.error = null;
		$scope.controller.loginState.isSubmitting = true;
		
		$timeout(function () {
			var loginInfo= $scope.controller.loginState;
		
			var onSuccess = function (response) {
				var path = $window.location.pathname;

				if (path == Luma.paths.context + "/" || path == Luma.paths.context + "/resolve/site/service/login.jsp") {
					$window.location = response.data;
				}
				else {
					$window.location.reload();
				}
			};
		
			var onError = function (response) {
				$scope.controller.loginState.isSubmitting = false;
			
				if (response.data) {
					$scope.controller.loginState.error = {
						key: response.data.reasons[0].key,
						value: response.data.reasons[0].value.replace(/\./g, "\n")
					};
				}
			};
		
			LumaPublicAPI.login(loginInfo).then(onSuccess).catch(onError);
		}, 500);
	};
}]);